<template>
  <div :id="componentId" style="height: 100%, width: 100%">
    <b-modal v-model="state.modalShow" size="md" :title="$t('resource.title_unit_edit')" footer-class="footerClass" 
      no-close-on-backdrop  content-class="resource-unit-modal shadow"
      @hidden="hidden"
      scrollable>

      <b-alert variant="danger" dismissible v-model="state.errorShow">
        <font-awesome-icon :icon="['fas', 'triangle-exclamation']"/>&nbsp;&nbsp;{{ $t('error.attention_required') }} 
      </b-alert>

      <div class="container pl-0">
        <b-row>
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('resource.field.resource')" label-for="resourceName">
              <b-input-group>
                <b-form-input type="text" :placeholder="$t('resource.select_resource')" readonly class="form-label"
                    v-on="canList() ? { click: resourceSelectorOpen } : {}"
                    v-model="resourceName"
                    :data-vv-as="$t('resource.field.resource')"
                    data-vv-name="resourceName"
                    data-vv-delay="500"
                    v-validate="{ required: true }"/> 
                <b-input-group-append v-if="canList()">
                  <b-button @click="resourceSelectorOpen"><span>{{ $t('button.select') }}</span></b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showResourceNameError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resourceName') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <template v-if="customFieldMap['name'] != null">
            <b-col v-for="(field, index) in customFieldMap['name']" :key="'name'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          <template v-if="customFieldMap['default'] != null">
            <b-col v-for="(field, index) in customFieldMap['default']" :key="index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>

          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('resource.field.unit')" label-for="resourceUnit">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button id="UNIT_SUBTRACT" @click.prevent="unitAddMinus(-1)">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="UNIT_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('resource.button.unit_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input type="text" class="form-label"
                    v-model="resourceUnit" @blur="resourceUnitFormat"/>
                <b-input-group-append>
                  <b-button id="UNIT_ADD" @click.prevent="unitAddMinus(1)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="UNIT_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('resource.button.unit_add')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
              <b-form-invalid-feedback class="alert-danger form-field-alert" :class="{ 'd-block': showResourceUnitError }">
                <font-awesome-icon :icon="['far', 'circle-exclamation']"/>&nbsp;&nbsp;{{ errors.first('resourceUnit') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['unit'] != null">
            <b-col v-for="(field, index) in customFieldMap['unit']" :key="'unit'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>
          
          <b-col cols="12" class="pr-0">
            <b-form-group :label="$t('resource.field.utilization')" label-for="utilization">
              <b-input-group>
                <b-input-group-prepend>
                  <b-button id="UTILIZATION_SUBTRACT" @click.prevent="utilizationAddMinus(-1)">
                    <font-awesome-icon :icon="['far', 'minus']"/>
                    <b-popover
                      target="UTILIZATION_SUBTRACT"
                      placement="top"
                      triggers="hover"
                      :content="$t('resource.button.utilization_subtract')">
                    </b-popover>
                  </b-button>
                </b-input-group-prepend>
                <b-form-input id="utilization" v-model="resourceUtilization" @blur="utilizationFormat" type="text"></b-form-input>
                <b-input-group-append>
                  <b-button id="UTILIZATION_ADD" @click.prevent="utilizationAddMinus(1)">
                    <font-awesome-icon :icon="['far', 'plus']"/>
                    <b-popover
                      target="UTILIZATION_ADD"
                      placement="top"
                      triggers="hover"
                      :content="$t('resource.button.utilization_add')">
                    </b-popover>
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <span id="BTN_UTILIZATION_COMPLETE">
                    <b-button @click.prevent="utilizationMax()" :variant="resourceUtilization == 100? 'success':'secondary'">
                      <font-awesome-icon :icon="['far', 'check']"/>
                    </b-button>
                  </span>
                  <b-popover target="BTN_UTILIZATION_COMPLETE" triggers="hover" placement="topleft">
                    {{ $t('resource.popover.progressMax') }}
                  </b-popover>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <template v-if="customFieldMap['utilization'] != null">
            <b-col v-for="(field, index) in customFieldMap['utilization']" :key="'utilization'+index" cols="12" class="pr-0">
              <b-form-group>
                <template v-if="field.type !== 'Boolean'" slot="label">
                  <span class="mr-2">{{ field.displayName }}</span>
                  <span v-if="field.description">
                    <font-awesome-icon :id="`${componentId}_${field.name}`" :icon="['far', 'circle-question']" :style="{ color: 'var(--form-control-placeholder)', fontSize: '0.9em' }"/>
                    <b-popover :target="`${componentId}_${field.name}`" triggers="hover" placement="top">
                      {{ field.description }}
                    </b-popover>  
                  </span>
                </template>
                <CustomField v-model="customData[field.name]" :componentId="componentId" :field="field"></CustomField>
              </b-form-group>
            </b-col>
          </template>
        </b-row>
      </div>
      
      <template v-slot:modal-footer="{}">
        <b-button v-if="showDelete" size="sm" class="button-delete" @click="remove">{{ $i18n.t('button.delete') }}</b-button>
        <b-form-checkbox v-if="canApplyAll" class="apply-to-all" v-model="applyToAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <!-- resource selector -->
    <GenericSelectorModalForAdmin v-if="state.resourceSelectorShow"
      :show.sync="state.resourceSelectorShow" 
      :entityService="resourceUtil"
      entity="RESOURCE"
      :preselected="this.id"
      nonAdmin
      singleSelection
      @ok="resourceSelectorOk"
    />
  </div>
</template>

<script>
import { fieldValidateUtil } from '@/script/helper-field-validate';
import { toFixed } from '@/helpers/task-duration-process';
import { getCustomFieldInfo, customFieldValidate } from '@/helpers/custom-fields';
import { getAppendAfterObjectWithTopDownRelationship } from '@/components/modal/script/field';
import { resourceUtil } from '@/views/management/script/resource';

export default {
  name: 'ResourceUnitModal',
  components: {
    CustomField: () => import('@/components/CustomField.vue'),
    GenericSelectorModalForAdmin : () => import('@/components/modal/GenericSelectorModalForAdmin')
  },
  props: {
    uuId: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    unit: {
      type: Number,
      default: 1
    },
    utilization: {
      type: Number,
      default: 1
    },
    show: {
      type: Boolean,
      required: true
    },
    canApplyAll: {
      type: Boolean,
      default: false
    },
    showDelete: {
      type: Boolean,
      default: false
    },
    edgeName: {
      type: String,
      default: null
    },
    cData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      permissionName: "RESOURCE",
      state: {
        modalShow: false,
        errorShow: false,
        resourceSelectorShow: false
      },
      id: null,
      resourceName: null,
      resourceUnit: null,
      resourceUtilization: null,
      resourceSelector: [],
      oldId: null,
      applyToAll: false,
      
      customFields: [],
      customData: {},
      customFieldMap: {},
      resourceEditId: null
    }
  },
  created() {
    this.resourceUtil = resourceUtil;
    // this.updateShow(this.show);
    this.updateUuId(this.uuId);
    this.updateName(this.name);
    this.updateUnit(this.unit);
    this.updateUtilization(this.utilization);
    this.customData = { ...this.cData };
  },
  beforeDestroy() {
    this.resourceUtil = null;
  },
  mounted() {
    this.processWhenShowModal(this.show);
  },
  watch: {
    show(newValue) {
      this.processWhenShowModal(newValue);
    },
    uuId(newValue) {
      this.updateUuId(newValue);
    },
    name(newValue) {
      this.updateName(newValue);
    },
    unit(newValue) {
      this.updateUnit(newValue);
    },
    utilization(newValue) {
      this.updateUtilization(newValue);
    },
    cData(newValue) {
      this.customData = { ...newValue };
    }
  },
  computed: {
    componentId() {
      return `RESOURCE_UNIT_FORM_${this.id}`;
    },
    customFieldsFiltered() {
      return this.customFields;
    },
    showResourceNameError() {
      return fieldValidateUtil.hasError(this.errors, 'resourceName');
    },
    showResourceUnitError() {
      return fieldValidateUtil.hasError(this.errors, 'resourceUnit');
    }
  },
  methods: {
    async processWhenShowModal(newValue) {
      if (newValue) {
        await getCustomFieldInfo(this, 'RESOURCE_LINK', this.edgeName);
        this.$validator.resume();
        if (this.customFields.length == 0) {
          this.customFieldMap = {};
        } else {
          this.customFieldMap = getAppendAfterObjectWithTopDownRelationship(this.customFields);
        }
      }
      this.updateShow(newValue);
      this.applyToAll = false;
    },
    integerFormatter(value) {
      return value && !isNaN(value)? parseInt(value): null;
    },
    updateShow(newValue) {
      this.state.modalShow = newValue;
    },
    updateUuId(newValue) {
      this.id = newValue;
    },
    updateName(newValue) {
      this.resourceName = newValue;
    },
    updateUnit(newValue) {
      this.resourceUnit = newValue? newValue:1;
    },
    updateUtilization(newValue) {
      this.resourceUtilization = newValue? toFixed(newValue * 100, 2) : 100;
    },
    ok() {
      const customFields = this.customFieldsFiltered;
      for (const field of customFields) {
        if (!customFieldValidate(field, this.customData[field.name])) {
          field.showError = true;
          return;  
        }
      }
      
      this.$validator.validate().then(valid => {
        if (valid) {
          this.state.errorShow = false;
          this.$emit('ok', { id: this.id, name: this.resourceName, unit: this.resourceUnit, utilization: parseFloat(this.resourceUtilization / 100), oldId: this.oldId, applyToAll: this.applyToAll, customFields: this.customFields, customData: this.customData });
          this.$emit('update:show', false);
        } else {
          this.state.errorShow = true;
        }
      });
    },
    cancel() {
      this.$emit('cancel');
      this.$emit('update:show', false);
    },
    remove() {
      this.$emit('remove', { id: this.id });
      this.$emit('update:show', false);
    },
    hidden() {
      this.$validator.pause();
      this.$emit('update:show', false);
    },
    resourceSelectorOpen() {
      this.resourceSelector.splice(0, this.resourceSelector.length, this.id);
      this.state.resourceSelectorShow = true;
    },
    resourceSelectorOk({ details }) {
      const selected = details[0];
      this.oldId = this.uuId;
      this.id = selected.uuId;
      this.resourceName = selected.name;
    },
    restrictDecimal () {
      this.$nextTick(() => {
        this.resourceUtilization=toFixed(this.resourceUtilization, 2);
        if (this.resourceUtilization > 100) {
          this.resourceUtilization = 100;
        }
        else if (this.resourceUtilization < 0) {
          this.resourceUtilization = 0;
        }
      });
    },
    unitAddMinus(delta) {
      if(this.resourceUnit == null || isNaN(this.resourceUnit)) {
        this.resourceUnit = 1;
      }
      if (parseInt(this.resourceUnit) === 1 && delta === -1) {
        return;
      }
      const value = parseInt(this.resourceUnit);
      this.resourceUnit = value + delta;
    },
    utilizationAddMinus(delta) {
      if(isNaN(this.resourceUtilization)) {
        this.resourceUtilization = 0;
      }
      const value = parseInt(this.resourceUtilization);
      this.resourceUtilization = value + delta;
      // round to nearest 10
      if (this.resourceUtilization % 10 !== 0) {
        if (delta > 0) {
          this.resourceUtilization = Math.ceil(this.resourceUtilization / 10) * 10;
        }
        else {
          this.resourceUtilization = Math.floor(this.resourceUtilization / 10) * 10;
        }
      }
      this.utilizationFormat();
    },
    utilizationMax() {
      this.resourceUtilization = 100;
      this.utilizationFormat();
    },
    utilizationFormat() {
      if(this.resourceUtilization == null || isNaN(this.resourceUtilization) || (typeof this.resourceUtilization == 'string' && this.resourceUtilization.trim().length == 0)) {
        this.resourceUtilization = 0;
      }
      const value = parseInt(this.resourceUtilization);
      if(value < 0) {
        this.resourceUtilization = 0;
      } else if(value > 100) {
        this.resourceUtilization = 100;
      } else {
        this.resourceUtilization = value;
      }
    },
    resourceUnitFormat() {
      if (this.resourceUnit == null || isNaN(this.resourceUnit) || isNaN(this.resourceUnit) || (typeof this.resourceUnit == 'string' && this.resourceUnit.trim().length == 0)) {
        this.resourceUnit = 1;
      }
      const value = parseInt(this.resourceUnit);
      if(value < 1) {
        this.resourceUnit = 1;
      } else if(value > 100) {
        this.resourceUnit = 100;
      } else {
        this.resourceUnit = value;
      }
    }
  }

}
</script>
<style lang="scss">
.resource-unit-modal .apply-to-all {
  position: absolute;
  left: 15px;
}

.resource-unit-modal .button-delete {
  position: absolute;
  left: 15px;
}
</style>