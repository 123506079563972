var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.$t("resource.title_unit_edit"),
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "resource-unit-modal shadow",
            scrollable: "",
          },
          on: { hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                return [
                  _vm.showDelete
                    ? _c(
                        "b-button",
                        {
                          staticClass: "button-delete",
                          attrs: { size: "sm" },
                          on: { click: _vm.remove },
                        },
                        [_vm._v(_vm._s(_vm.$i18n.t("button.delete")))]
                      )
                    : _vm._e(),
                  _vm.canApplyAll
                    ? _c(
                        "b-form-checkbox",
                        {
                          staticClass: "apply-to-all",
                          model: {
                            value: _vm.applyToAll,
                            callback: function ($$v) {
                              _vm.applyToAll = $$v
                            },
                            expression: "applyToAll",
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("apply_to_all")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.state.errorShow,
                callback: function ($$v) {
                  _vm.$set(_vm.state, "errorShow", $$v)
                },
                expression: "state.errorShow",
              },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.$t("error.attention_required")) + " "),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "container pl-0" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("resource.field.resource"),
                            "label-for": "resourceName",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-form-input",
                                _vm._g(
                                  {
                                    directives: [
                                      {
                                        name: "validate",
                                        rawName: "v-validate",
                                        value: { required: true },
                                        expression: "{ required: true }",
                                      },
                                    ],
                                    staticClass: "form-label",
                                    attrs: {
                                      type: "text",
                                      placeholder: _vm.$t(
                                        "resource.select_resource"
                                      ),
                                      readonly: "",
                                      "data-vv-as": _vm.$t(
                                        "resource.field.resource"
                                      ),
                                      "data-vv-name": "resourceName",
                                      "data-vv-delay": "500",
                                    },
                                    model: {
                                      value: _vm.resourceName,
                                      callback: function ($$v) {
                                        _vm.resourceName = $$v
                                      },
                                      expression: "resourceName",
                                    },
                                  },
                                  _vm.canList()
                                    ? { click: _vm.resourceSelectorOpen }
                                    : {}
                                )
                              ),
                              _vm.canList()
                                ? _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: _vm.resourceSelectorOpen,
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.$t("button.select"))
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showResourceNameError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("resourceName")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["name"] != null
                    ? _vm._l(
                        _vm.customFieldMap["name"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "name" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _vm.customFieldMap["default"] != null
                    ? _vm._l(
                        _vm.customFieldMap["default"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("resource.field.unit"),
                            "label-for": "resourceUnit",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "UNIT_SUBTRACT" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.unitAddMinus(-1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "UNIT_SUBTRACT",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "resource.button.unit_subtract"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                staticClass: "form-label",
                                attrs: { type: "text" },
                                on: { blur: _vm.resourceUnitFormat },
                                model: {
                                  value: _vm.resourceUnit,
                                  callback: function ($$v) {
                                    _vm.resourceUnit = $$v
                                  },
                                  expression: "resourceUnit",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "UNIT_ADD" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.unitAddMinus(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "UNIT_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "resource.button.unit_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-form-invalid-feedback",
                            {
                              staticClass: "alert-danger form-field-alert",
                              class: { "d-block": _vm.showResourceUnitError },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "circle-exclamation"] },
                              }),
                              _vm._v(
                                "  " +
                                  _vm._s(_vm.errors.first("resourceUnit")) +
                                  " "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["unit"] != null
                    ? _vm._l(
                        _vm.customFieldMap["unit"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "unit" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: _vm.$t("resource.field.utilization"),
                            "label-for": "utilization",
                          },
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c(
                                "b-input-group-prepend",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "UTILIZATION_SUBTRACT" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.utilizationAddMinus(-1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "minus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "UTILIZATION_SUBTRACT",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "resource.button.utilization_subtract"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("b-form-input", {
                                attrs: { id: "utilization", type: "text" },
                                on: { blur: _vm.utilizationFormat },
                                model: {
                                  value: _vm.resourceUtilization,
                                  callback: function ($$v) {
                                    _vm.resourceUtilization = $$v
                                  },
                                  expression: "resourceUtilization",
                                },
                              }),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { id: "UTILIZATION_ADD" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.utilizationAddMinus(1)
                                        },
                                      },
                                    },
                                    [
                                      _c("font-awesome-icon", {
                                        attrs: { icon: ["far", "plus"] },
                                      }),
                                      _c("b-popover", {
                                        attrs: {
                                          target: "UTILIZATION_ADD",
                                          placement: "top",
                                          triggers: "hover",
                                          content: _vm.$t(
                                            "resource.button.utilization_add"
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "span",
                                    {
                                      attrs: { id: "BTN_UTILIZATION_COMPLETE" },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant:
                                              _vm.resourceUtilization == 100
                                                ? "success"
                                                : "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.preventDefault()
                                              return _vm.utilizationMax()
                                            },
                                          },
                                        },
                                        [
                                          _c("font-awesome-icon", {
                                            attrs: { icon: ["far", "check"] },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        target: "BTN_UTILIZATION_COMPLETE",
                                        triggers: "hover",
                                        placement: "topleft",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "resource.popover.progressMax"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.customFieldMap["utilization"] != null
                    ? _vm._l(
                        _vm.customFieldMap["utilization"],
                        function (field, index) {
                          return _c(
                            "b-col",
                            {
                              key: "utilization" + index,
                              staticClass: "pr-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c(
                                "b-form-group",
                                [
                                  field.type !== "Boolean"
                                    ? _c("template", { slot: "label" }, [
                                        _c("span", { staticClass: "mr-2" }, [
                                          _vm._v(_vm._s(field.displayName)),
                                        ]),
                                        field.description
                                          ? _c(
                                              "span",
                                              [
                                                _c("font-awesome-icon", {
                                                  style: {
                                                    color:
                                                      "var(--form-control-placeholder)",
                                                    fontSize: "0.9em",
                                                  },
                                                  attrs: {
                                                    id:
                                                      _vm.componentId +
                                                      "_" +
                                                      field.name,
                                                    icon: [
                                                      "far",
                                                      "circle-question",
                                                    ],
                                                  },
                                                }),
                                                _c(
                                                  "b-popover",
                                                  {
                                                    attrs: {
                                                      target:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      triggers: "hover",
                                                      placement: "top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          field.description
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("CustomField", {
                                    attrs: {
                                      componentId: _vm.componentId,
                                      field: field,
                                    },
                                    model: {
                                      value: _vm.customData[field.name],
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.customData,
                                          field.name,
                                          $$v
                                        )
                                      },
                                      expression: "customData[field.name]",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          )
                        }
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.state.resourceSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.state.resourceSelectorShow,
              entityService: _vm.resourceUtil,
              entity: "RESOURCE",
              preselected: this.id,
              nonAdmin: "",
              singleSelection: "",
            },
            on: {
              "update:show": function ($event) {
                return _vm.$set(_vm.state, "resourceSelectorShow", $event)
              },
              ok: _vm.resourceSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }